<template>
  <div class="flex flex-col space-y-1 mb-5">
    <InputLabel v-if="label" :id="id || name" :required="required">
      {{ label }}
    </InputLabel>
    <div class="flex flex-col space-y-0.5">
      <div
        class="relative border border-gray-300 focus-within:shadow-blue-100 focus-within:border-blue-300 focus-within:ring-1 focus:ring-blue-300 shadow-sm rounded"
        :class="[
          {
            'border-red-300 hover:border-red-400': error || hasMaxLengthError,
            'bg-white hover:border-gray-400': !disabled,
            'cursor-not-allowed bg-gray-50': disabled,
          },
        ]"
      >
        <textarea
          :id="id || name"
          v-model="inputValue"
          :name="name"
          :rows="rows"
          :placeholder="placeholder"
          :aria-invalid="!!error"
          :required="required"
          :maxlength="maxLength"
          :disabled="disabled"
          class="appearance-none placeholder-gray-300 border-none text-gray-500 font-medium text-base md:text-xs p-3 pb-0 w-full focus:outline-none focus:ring-0 rounded"
          @input.stop
          @focus="handleFocus"
          @blur="handleBlur"
        />
        <div
          class="w-full flex text-xs"
          :class="
            hasSlot('belowInput')
              ? 'justify-between  pl-3 pr-1 py-2 rounded-b border-t border-gray-200'
              : 'justify-end pr-1 pb-1'
          "
        >
          <slot name="belowInput" />
          <span
            v-if="maxLength"
            class="px-2 py-0.5 rounded"
            :class="
              showOverflowWarning
                ? 'bg-red-300 text-white'
                : characterCount > maxLength
                ? 'bg-white text-red-300'
                : 'bg-white text-gray-400'
            "
            data-testid="char-count-message"
          >
            {{ characterCountMessage }}
          </span>
        </div>
        <div class="absolute -right-6 top-3 flex items-center">
          <tooltip-button v-if="tooltip" :tooltip="tooltip"></tooltip-button>
        </div>
        <slot name="afterInput" />
      </div>
      <error-message v-if="hasMaxLengthError" data-testid="max-length-error-message">{{
        maxLengthErrorMessage
      }}</error-message>
      <error-message v-else-if="error" data-testid="error-message">{{ error }}</error-message>
      <help-message v-if="helpText">{{ helpText }}</help-message>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, useSlots } from "vue"
import InputLabel from "./_base/InputLabel.vue"
import HelpMessage from "./_base/HelpMessage.vue"
import ErrorMessage from "./_base/ErrorMessage.vue"
import TooltipButton from "./_base/TooltipButton.vue"
import { calculateCharacterCount } from "@/helpers/string-helpers"

const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: null,
  },
  rows: {
    type: [String, Number],
    default: "4",
  },
  placeholder: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  error: {
    type: [String, Boolean],
    default: false,
  },
  maxLengthErrorMessage: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  helpText: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: () => null,
  },
  tooltip: {
    type: String,
    default: null,
  },
  assumeEnterAsManyCharacters: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(["update:modelValue", "focus", "blur"])
const inputValue = ref(props.modelValue)
const showOverflowWarning = ref(false)
const characterCount = ref(0)

const slots = useSlots()
const hasSlot = name => {
  return !!slots[name]
}
const characterCountMessage = computed(() => characterCount.value + " / " + props.maxLength)

const handleFocus = () => {
  emit("focus")
}
const handleBlur = () => {
  emit("blur")
}

let hideWarningTimeout = null
const warn = () => {
  showOverflowWarning.value = true

  if (hideWarningTimeout) {
    clearTimeout(hideWarningTimeout)
  }

  hideWarningTimeout = setTimeout(() => (showOverflowWarning.value = false), 1000)
}

if (props.modelValue) {
  // set the character count
  characterCount.value = calculateCharacterCount(
    props.modelValue,
    props.assumeEnterAsManyCharacters,
  )
}

const hasMaxLengthError = computed(() => props.maxLength && characterCount.value > props.maxLength)

watch(
  () => [inputValue.value, props.modelValue],
  ([newInputValue, newModelValue], [oldInputValue, oldModelValue]) => {
    if (newModelValue !== oldModelValue && newModelValue !== inputValue.value) {
      inputValue.value = newModelValue
    } else if (newInputValue !== oldInputValue) {
      characterCount.value = calculateCharacterCount(
        newInputValue,
        props.assumeEnterAsManyCharacters,
      )
      if (props.maxLength && newInputValue && characterCount.value > props.maxLength) {
        warn()
      }
      emit("update:modelValue", inputValue.value)
    }
  },
)
</script>
